import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion as m } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const CoffeeImageStyle = styled.div`
  /* .coffee-image {
    width: 600px;
    height: 600px;
  } */
  .second-image,
  .third-image {
    display: none;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    .second-image,
    .third-image {
      display: flex;
    }
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
    justify-content: center;
    div {
      display: flex;
      justify-content: center;
    }
  }
`;

export default function CoffeeImage({ images }) {
  const [mainImgRef] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });
  const [leftImgRef, leftImgInView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });
  const [rightImgRef, rightImgInView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });
  const imageGetter2 = getImage(images[1].localFile);
  const imageGetter1 = getImage(images[0].localFile);
  const imageGetter3 = getImage(images[2].localFile);
  return (
    <CoffeeImageStyle>
      <m.div
        ref={leftImgRef}
        initial={{ x: 250, opacity: 0 }}
        transition={{ duration: 0.2, delay: 1.3 }}
        animate={
          leftImgInView
            ? { x: 0, scale: 0.7, opacity: 1 }
            : { x: 250, scale: 0.7, opacity: 0 }
        }
        className="second-image"
      >
        <GatsbyImage
          className="coffee-image"
          image={imageGetter2}
          alt={images[1].alternativeText}
        />
      </m.div>
      <m.div
        ref={mainImgRef}
        className="first-image"
        transition={{ duration: 1.2 }}
      >
        <GatsbyImage
          className="coffee-image"
          image={imageGetter1}
          alt={images[0].alternativeText}
        />
      </m.div>
      <m.div
        ref={rightImgRef}
        initial={{ x: -250, opacity: 0 }}
        transition={{ duration: 0.2, delay: 1.3 }}
        animate={
          rightImgInView
            ? { x: 0, scale: 0.7, opacity: 1 }
            : { x: -250, scale: 0.7, opacity: 0 }
        }
        className="third-image"
      >
        <GatsbyImage
          className="coffee-image"
          image={imageGetter3}
          alt={images[2].alternativeText}
        />
      </m.div>
    </CoffeeImageStyle>
  );
}
