import React from "react";
import { graphql } from "gatsby";
import HeroCoffee from "../components/HeroCoffee";
import CoffeeInfoCard from "../components/CoffeeInfoCard";

export default function CoffeePage({ data }) {
  const { coffeeCard, description, exploreCoffeeMachines, videobc, title } =
    data.allStrapiCoffeeMachine.nodes[0];

  return (
    <>
      <HeroCoffee
        description={description}
        title={title}
        button={exploreCoffeeMachines}
        video={videobc}
      />
      <div id="coffee-machines-wrapper">
        {coffeeCard.map(item => (
          <CoffeeInfoCard key={item.id} {...item} />
        ))}
      </div>
    </>
  );
}

export const coffeeMachinesPageQuery = graphql`
  query coffeeMachinesPageQuery {
    allStrapiCoffeeMachine {
      nodes {
        title
        description {
          data {
            childrenMarkdownRemark {
              html
            }
          }
        }
        exploreCoffeeMachines {
          buttonText
          link
        }
        coffeeCard {
          id
          name
          completeDescription {
            data {
              childrenMarkdownRemark {
                html
              }
            }
          }
          coffeeFeature {
            id
            feature
          }
          coffeeImage {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: PNG
                  quality: 100
                  layout: CONSTRAINED
                  width: 600
                  height: 600
                )
              }
            }
          }
          brochure {
            url
            name
            caption
          }
          userManual {
            url
            name
            caption
          }
          summary {
            data {
              childrenMarkdownRemark {
                html
              }
            }
          }
          completeDescription {
            data {
              childrenMarkdownRemark {
                html
              }
            }
          }
          coffeeMachineOverview {
            title
            subTitle {
              data {
                childrenMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        videobc {
          url
        }
      }
    }
  }
`;
