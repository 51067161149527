import React from "react";
import styled from "styled-components";
import { px } from "../utils";

const CustomLinkStyle = styled.a`
    text-transform: uppercase;
    letter-spacing: 1.5px;
    background-color: ${({ theme: { palette } }) => palette.primary.main};
    color: ${({ theme: { palette } }) => palette.primary[50]};
    padding: ${px(5)};
`;


export default function CustomLink({ text, link }) {
  return <CustomLinkStyle href={`${link}`}>{text}</CustomLinkStyle>
}