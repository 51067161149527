import React from "react";
import styled from "styled-components";
import ContainerStyle from "../styles/ContainerStyle";
import CustomLink from "./CustomLink";
import { motion as m } from "framer-motion";
import videoPlaceholder from "../assets/static-videos/promo-video.mp4";

const HeroCoffeeStyle = styled.section`
  --heroHeight: calc(100vh - 110px);
  .video-wrapper {
    position: relative;
    width: 100vw;
    height: var(--heroHeight);
    margin-left: -50vw;
    margin-right: -50vw;
    left: 50%;
    right: 50%;
    .video-opacity {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }
    & video {
      object-fit: cover;
      height: var(--heroHeight);
      width: 100%;
    }
    .content {
      top: 40%;
      position: absolute;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        text-transform: capitalize;
        color: ${({ theme: { palette } }) => palette.primary[50]};
      }
      .description {
        color: ${({ theme: { palette } }) => palette.primary[200]};
        font-size: ${({ theme: { typography } }) => `${typography.body2}px`};
      }
      a {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 50px;
        border: 2px solid ${({ theme: { palette } }) => palette.primary[50]};
        background-color: transparent;
        color: ${({ theme: { palette } }) => palette.primary[50]};
        font-weight: 500;
      }
    }
  }
`;

const transition = { duration: 1, ease: "backInOut" };

const textVariants = {
  initial: {
    opacity: 0,
    x: "-100px",
  },
  enter: {
    x: 0,
    opacity: 1,
    transition,
  },
};
const buttonVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: { ...transition, duration: 0.5, delay: 1 },
  },
};
export default function HeroCoffee({
  description,
  title,
  button: { link, buttonText },
  video,
}) {
  return (
    <HeroCoffeeStyle>
      <div className="video-wrapper">
        <div className="video-opacity"></div>
        <video width="100%" muted autoPlay loop>
          <source
            src={!video ? `${videoPlaceholder}#t=0.5` : `${video.url}#t=0.5`}
            type="video/mp4"
          />
        </video>
        <ContainerStyle className="content">
          <m.h1 variants={textVariants} initial="initial" animate="enter">
            {title}
          </m.h1>
          <m.div
            variants={textVariants}
            initial={{ ...textVariants.initial, x: "100px" }}
            animate="enter"
            className="description"
            dangerouslySetInnerHTML={{
              __html: description.data.childrenMarkdownRemark[0].html,
            }}
          />
          <m.div variants={buttonVariants} initial="initial" animate="enter">
            <CustomLink link={link} text={buttonText} />
          </m.div>
        </ContainerStyle>
      </div>
    </HeroCoffeeStyle>
  );
}
