import React from "react";
import styled from "styled-components";

const CoffeeDownloadsStyle = styled.ul`
  --red: ${({ theme: { palette } }) => palette.secondary.main};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 2fr));
  align-items: baseline;
  gap: 10px;
  & > div {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .brochure-list a {
    border: 2px solid ${({ theme: { palette } }) => palette.primary[400]};
    display: inline-block;
    padding: 10px;
    border-radius: 50px;
    color: white;
    background: ${({ theme: { palette } }) => palette.primary[400]};
    transition: background 100ms ease-in-out, border 100ms ease-in-out;
    &:hover {
      background: var(--red);
      border: 2px solid var(--red);
    }
  }
  .manuals-list a {
    border: 2px solid ${({ theme: { palette } }) => palette.primary[400]};
    display: inline-block;
    padding: 10px;
    border-radius: 50px;
    color: white;
    background: ${({ theme: { palette } }) => palette.primary[400]};
    transition: background 100ms ease-in-out, border 100ms ease-in-out;
    &:hover {
      background: var(--red);
      border: 2px solid var(--red);
    }
  }
  .manuals li,
  .brochures li {
    margin-bottom: 10px;
  }
  li.manuals-list,
  li.brochure-list {
    display: flex;
    flex-direction: column;
    & a:not(:last-child) {
      margin-bottom: 10px;
    }
    & a {
      overflow-wrap: anywhere;
    }
  }
`;

export default function CoffeeDownloads({ brochure, userManual }) {
  console.log(brochure, userManual);
  return (
    <CoffeeDownloadsStyle>
      {brochure && (
        <div className="brochures">
          <h4>Brochures</h4>
          {brochure.map(({ url, caption }, i) => (
            <li className="brochure-list" key={i}>
              <a rel="noreferrer" href={url} target="_blank">
                {caption}
              </a>
            </li>
          ))}
        </div>
      )}
      {userManual && (
        <div className="manuals">
          <h4>Manuals</h4>
          {userManual.map(({ url, caption }, i) => (
            <li className="manuals-list" key={i}>
              <a rel="noreferrer" href={url} target="_blank">
                {caption}
              </a>
            </li>
          ))}
        </div>
      )}
    </CoffeeDownloadsStyle>
  );
}
