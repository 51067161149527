import React from "react";
import styled from "styled-components";
import { pxToRem } from "../utils";

const CoffeeMachineOverviewBlockStyle = styled.li`
  background: ${({ theme: { palette } }) => palette.primary[500]};
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
  &:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    transition: box-shadow 200ms ease-in-out;
  }
  h5 {
    color: ${({ theme: { palette } }) => palette.primary[200]};
    font-size: ${({ theme: { typography } }) => pxToRem(typography.h5.md)};
  }
  .subtitle-overview {
    color: ${({ theme: { palette } }) => palette.primary[50]};
  }
`;

export default function CoffeeMachineOverviewBlock({ title, subTitle }) {
  return (
    <CoffeeMachineOverviewBlockStyle>
      <h5>{title}</h5>
      <div
        className="subtitle-overview"
        dangerouslySetInnerHTML={{
          __html: subTitle.data.childrenMarkdownRemark[0].html,
        }}
      />
    </CoffeeMachineOverviewBlockStyle>
  );
}
