import React from "react";
import styled from "styled-components";
import CoffeeDownloads from "./CoffeeDownloads";
import CoffeeMachineOverviewBlock from "./CoffeeMachineOverviewBlock";
import { FatArrowup } from "./CustomIcons";

const CoffeeMoreInfoStyle = styled.div`
  --red: ${({ theme: { palette } }) => palette.secondary.main};
  background: ${({ theme: { palette } }) => palette.primary[200]};
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 10px auto;
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  h2,
  .full-description p {
    text-align: center;
  }

  .full-description p {
    padding-left: 10px;
  }

  .overview-of-features-wrapper {
    ul {
      width: 100%;
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
    }
  }

  .overview-of-features-wrapper,
  .download-wrapper {
    margin-top: 40px;
  }

  .close-toggle {
    background: var(--red);
    color: white;
    width: 70px;
    height: 70px;
    display: flex: 
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    transition: box-shadow 200ms ease-in-out;
    &:hover {
      box-shadow: 2px 2px 7px rgba(0,0,0,0.3);
    }
  }
`;

export default function CoffeeMoreInfo({
  completeDescription,
  userManual,
  states: { setToggleInfo },
  coffeeMachineOverview,
  brochure,
  name,
}) {
  return (
    <CoffeeMoreInfoStyle>
      {completeDescription && (
        <div className="description-wrapper">
          <h2>
            {name} <span className="highlight">Description</span>
          </h2>
          <div
            className="full-description"
            dangerouslySetInnerHTML={{
              __html:
                completeDescription?.data?.childrenMarkdownRemark[0]?.html,
            }}
          ></div>
        </div>
      )}
      <div className="overview-of-features-wrapper">
        <h2>
          {name} <span className="highlight">Overview</span>
        </h2>
        <ul>
          {coffeeMachineOverview.map((block, i) => (
            <CoffeeMachineOverviewBlock key={i} {...block} />
          ))}
        </ul>
      </div>
      <div className="download-wrapper">
        <h2>
          {name} <span className="highlight">Downloads</span>
        </h2>
        <ul>
          <CoffeeDownloads brochure={brochure} userManual={userManual} />
        </ul>
      </div>
      <button className="close-toggle" onClick={() => setToggleInfo(false)}>
        Close
        <FatArrowup />
      </button>
    </CoffeeMoreInfoStyle>
  );
}
