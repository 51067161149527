import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { pxToRem } from "../utils";
import CoffeeImage from "./CoffeeImages";
import CoffeeMoreInfo from "./CoffeeMoreInfo";
import { FatArrowdown } from "./CustomIcons";

const CoffeePageStyle = styled.section`
  --red: ${({ theme: { palette } }) => palette.secondary.main};
  --primary50: ${({ theme: { palette } }) => palette.primary[50]};
  --primary300: ${({ theme: { palette } }) => palette.primary[300]};
  --primary600: ${({ theme: { palette } }) => palette.primary[600]};
  --primary700: ${({ theme: { palette } }) => palette.primary[700]};
  --primary900: ${({ theme: { palette } }) => palette.primary[900]};
  &:nth-child(odd),
  &:nth-child(even) {
    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    left: 50%;
    right: 50%;
    position: relative;
    padding: 45px;
  }

  &:nth-child(odd) {
    background-color: var(--primary300);
    .heading {
      color: var(--primary900);
    }
    .summary,
    .feature-lists {
      color: var(--primary700);
    }
  }
  &:nth-child(even) {
    background-color: var(--primary600);
    .heading {
      color: var(--primary50);
    }
    .summary {
      color: var(--primary50);
    }
    .feature-lists {
      color: var(--primary50);
    }
  }
  display: flex;
  flex-direction: column;
  gap: 50px;
  .coffee-details {
    .heading {
      margin-bottom: 30px;
      font-size: ${({ theme: { typography } }) => pxToRem(typography.h1.md)};
      text-align: center;
      &::first-letter {
        color: var(--red);
        font-size: 4rem;
      }
    }
    .summary {
      margin-bottom: 20px;
      text-align: center;
    }
    .feature-lists {
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      font-weight: 900;
      font-family: "montserrat";
      letter-spacing: 1px;
    }
    .more-btn {
      display: flex;
      align-items: center;
      background-color: var(--primary900);
      max-width: max-content;
      color: var(--primary50);
      height: 50px;
      padding: 20px;
      text-transform: capitalize;
      margin: 0 auto;
      transition: background-color 100ms ease-in-out;
      border-radius: 10px;
      &:hover {
        background-color: var(--red);
        transition: background-color 100ms ease-in-out;
      }
    }
    .feature-list::before {
      content: "✓";
      color: var(--red);
      margin-right: 5px;
    }
  }

  .toggle-animation-enter {
    transform: scaleY(0);
  }
  .toggle-animation-enter-active {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 300ms ease-in-out;
  }

  .toggle-animation-exit-active {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 300ms ease-in-out;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    min-height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
  }
`;

const TOGGLE_TRANSITION_TIME = 300;

export default function CoffeeInfoCard({
  brochure,
  coffeeMachineOverview,
  userManual,
  completeDescription,
  coffeeFeature,
  coffeeImage,
  summary,
  name,
}) {
  const [toggleInfo, setToggleInfo] = useState(false);

  function handleMoreInfo() {
    setToggleInfo(!toggleInfo);
  }

  return (
    <CoffeePageStyle>
      <div className="coffee-details">
        {summary && (
          <>
            <h2 className="heading">{name}</h2>
            <div
              className="summary"
              dangerouslySetInnerHTML={{
                __html: summary?.data?.childrenMarkdownRemark[0].html,
              }}
            />
          </>
        )}
        {coffeeFeature.length > 0 && (
          <ul className="feature-lists">
            {coffeeFeature.map(({ feature, id }) => (
              <li key={id} className="feature-list">
                {feature}
              </li>
            ))}
          </ul>
        )}
        <button className="more-btn" type="button" onClick={handleMoreInfo}>
          More Info <FatArrowdown />
        </button>
        <CSSTransition
          unmountOnExit
          mountOnEnter
          in={toggleInfo}
          timeout={TOGGLE_TRANSITION_TIME}
          classNames="toggle-animation"
        >
          <div>
            <CoffeeMoreInfo
              states={{ setToggleInfo }}
              userManual={userManual}
              brochure={brochure}
              coffeeMachineOverview={coffeeMachineOverview}
              completeDescription={completeDescription}
              name={name}
            />
          </div>
        </CSSTransition>
      </div>
      <CoffeeImage images={coffeeImage} />
    </CoffeePageStyle>
  );
}
